import React from 'react';
import './public.css';
import { Head } from "./components/Head";
import AudioPlayer from "react-modern-audio-player";
import { getTrack } from "./resources/playlist";

function App() {
    const driftToPeace = getTrack(0);
    return (
        <div>
            <Head title={"Liminal - Home"} description={"Liminal"} />
            <header>

            </header>
            <article className="container flex h-screen items-center justify-center">
                <div>
                    <h1 className="mb-2 text-center font-bold">Liminal</h1>
                    <p className="mb-10 text-center">Lorem ipsum odor amet, consectetuer adipiscing elit. Eros neque efficitur maximus pulvinar morbi non. Dapibus scelerisque dui duis natoque ligula imperdiet nec faucibus. Nisl eros habitant porta nisi gravida purus fringilla libero. Fermentum montes habitasse penatibus phasellus fermentum lacus quam.</p>
                    <h2 className="mb-0 text-center text-2xl font-bold">{ driftToPeace[0].name }</h2>
                    <p className="mb-4 text-center">{ driftToPeace[0].description }</p>
                    <AudioPlayer
                        playList={driftToPeace}
                        activeUI={{
                            all: true,
                            artwork: false,
                            playButton: true,
                            trackInfo: false,
                            trackTime: false,
                            repeatType: false,
                            playList: false,
                            volume: false,
                            progress: "bar"
                        }}
                        placement={{
                            interface: {
                                templateArea: { //@ts-ignore
                                    progress: "row2-1", //@ts-ignore
                                    playButton: "row1-2", //@ts-ignore
                                    playList: "row1-1",
                                },
                            },
                            player: "static",
                        }}
                    />
                </div>
            </article>
            <footer>

            </footer>
        </div>
    );
}

export default App;
