import React from "react"
import {Helmet} from "react-helmet"

interface HeadProps {
    title: string;
    description: string;
    meta?: {
        name: string,
        content: string
    }[];
    links?: {
        rel: string,
        href: string,
        sizes?: string
    }[];
    scripts?: {
        src: string,
        type: string
    }[];
}

const staticMeta: {name: string, content: string}[] = [
    {
        name: `viewport`,
        content: 'width=device-width, initial-scale=1',
    },{
        name: `charset`,
        content: 'utf-8',
    },{
        name: `theme_color`,
        content: '#ffffff',
    },{
        name: `msapplication-TileColor`,
        content: '#ffffff',
    },{
        name: `msapplication-TileImage`,
        content: process.env.PUBLIC_URL + '/assets/ms-icon-144x144.png',
    },
]

const staticLinks: {rel: string, href: string, type?: string, sizes?: string}[] = [
    {
        rel: 'manifest',
        href: process.env.PUBLIC_URL + '/manifest.json'
    },{
        rel: 'apple-touch-icon',
        sizes: '57x57',
        href: process.env.PUBLIC_URL + '/assets/apple-icon-57x57.png'
    },{
        rel: 'apple-touch-icon',
        sizes: '72x72',
        href: process.env.PUBLIC_URL + '/assets/apple-icon-72x72.png'
    },{
        rel: 'apple-touch-icon',
        sizes: '76x76',
        href: process.env.PUBLIC_URL + '/assets/apple-icon-76x76.png'
    },{
        rel: 'apple-touch-icon',
        sizes: '114x114',
        href: process.env.PUBLIC_URL + '/assets/apple-icon-114x114.png'
    },{
        rel: 'apple-touch-icon',
        sizes: '120x120',
        href: process.env.PUBLIC_URL + '/assets/apple-icon-120x120.png'
    },{
        rel: 'apple-touch-icon',
        sizes: '144x144',
        href: process.env.PUBLIC_URL + '/assets/apple-icon-144x144.png'
    },{
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: process.env.PUBLIC_URL + '/assets/apple-icon-152x152.png'
    },{
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: process.env.PUBLIC_URL + '/assets/apple-icon-180x180.png'
    },{
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: process.env.PUBLIC_URL + '/assets/favicon-16x16.png'
    },{
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: process.env.PUBLIC_URL + '/assets/favicon-32x32.png'
    },{
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
        href: process.env.PUBLIC_URL + '/assets/favicon-96x96.png'
    },{
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: process.env.PUBLIC_URL + '/assets/favicon-192x192.png'
    }
]

const staticScripts: {src: string, type: string, integrity?: string, crossorigin?: string, defer?: ""}[] = [

]

export const Head = ({title, description, meta = [], links = [], scripts = []}: HeadProps) => {
    return(
        <Helmet
            title = {title}
            htmlAttributes={{ lang: "en" }}
            meta = {meta.concat(
                staticMeta.concat([
                    {
                        name: `description`,
                        content: description,
                    },
                ])
            )}
            link = { links.concat(
                staticLinks
            )}
            script = { scripts.concat(
                staticScripts
            )}
        />
    )
}